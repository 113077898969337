<template>
  <div class="row">
    <div class="col-sm-8">
      <div class="card">
        <div class="card-body p-10">
          <template v-if="slides.length > 1">
            <Carousel
              :items-to-show="1"
              :wrap-around="false"
              v-model="currentSlide"
            >
              <Slide v-for="slide in slides" :key="slide">
                <template v-if="slide.type === 3">
                  <div class="iframe-wrapper">
                    <video width="100%" height="100%" controls>
                      <source :src="slide.link" type="video/mp4" />
                      {{ $t("video_tag_not_supported") }}
                    </video>
                  </div>
                </template>
                <template v-else-if="slide.type === 2">
                  <div class="iframe-wrapper">
                    <iframe
                      height="100%"
                      width="100%"
                      :src="slide.link"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </template>
                <template v-else>
                  <img
                    :src="slide.link"
                    loading="lazy"
                    style="width: 100%; object-fit: cover"
                  />
                </template>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>

            <Carousel
              id="thumbnails"
              :items-to-show="4"
              :wrap-around="true"
              v-model="currentSlide"
              class="carousel-small"
              ref="carousel"
            >
              <Slide v-for="(slide, slideIndex) in slides" :key="slide">
                <div class="slide--thumbnail">
                  <img
                    style="position: relative"
                    :src="slide.previewLink ? slide.previewLink : slide.link"
                    @click="slideTo(slideIndex)"
                  />
                </div>
                <img
                  v-if="slide.type !== 1"
                  src="/media/svg/misc/video-play.svg"
                  alt="Play"
                  style="position: absolute; cursor: pointer"
                  @click="slideTo(slideIndex)"
                />
              </Slide>
            </Carousel>
          </template>
          <template v-else-if="slides.length === 1">
            <img
              :src="slides[0].link"
              style="width: 100%; object-fit: cover; cursor: pointer"
            />
          </template>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <!--begin::Row-->
      <div class="card">
        <div class="card-body p-10">
          <div class="row mb-3" v-for="info in modInfo" :key="info.key">
            <!--begin::Label-->
            <label class="col-lg-4 fw-bold text-muted">{{
              $t(info.key)
            }}</label>
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8">
              <span class="fw-bolder fs-6 text-dark">{{
                info.translate ? $t(info.value) : info.value
              }}</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Row-->

          <button
            type="button"
            class="btn btn-primary er fs-6 px-8 py-4 w-100"
            @click="download"
          >
            {{ $t("download") }} ({{ bytesToSize(dto.size) }})
          </button>

          <button
            v-if="userHasGrant('ADMIN')"
            type="button"
            class="btn btn-danger er fs-6 px-8 py-4 w-100 mt-2"
            @click="edit"
          >
            Edit
          </button>

          <div class="text-center mt-2">
            <a
              href="/article/102"
              class="fw-bold"
              @click.prevent="
                this.$router.push({
                  name: 'article',
                  params: { id: 102 },
                })
              "
              >{{ $t('how_to_install') }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-8">
      <div class="card mt-3">
        <div class="card-body p-10">
          <div>
            <div class="fw-bolder" style="font-size: 26px">{{ dto.title }}</div>
            <div class="authors-container">
              <template
                v-for="(author, authorIndex) in dto.authors"
                :key="author"
              >
                <a
                  :href="'/author/' + (author.transliteratedName ? author.transliteratedName : author.id)"
                  @click.prevent="
                    this.$router.push({
                      name: 'author',
                      params: { id: (author.transliteratedName ? author.transliteratedName : author.id) },
                    })
                  "
                  class="author-link text-hover-primary fw-bolder fs-7"
                  style="color: #424242"
                >
                  <template v-if="author.avatar">
                    <img
                      class="author-avatar me-1 fs-5"
                      :src="author.avatar"
                      style="width: 14px; height: 14px; border-radius: 4px"
                    />
                    {{ author.name }}
                  </template>
                  <template v-else>
                    <i
                      class="fa fa-user author-icon me-1 fs-5"
                      style="color: #009ef7"
                    ></i>
                    {{ author.name }}
                  </template>
                </a>
                <span
                  class="fw-bolder fs-7"
                  v-if="authorIndex !== dto.authors.length - 1"
                >
                  ,&nbsp;&nbsp;
                </span>
              </template>
            </div>
            <p class="card-text">
              <small
                class="text-muted text-hover-primary"
                style="cursor: pointer"
                @click="searchByTag(tag)"
                v-for="(tag, tagIndex) in dto.tagIds"
                :key="tag"
                >{{ getTagName(tag)
                }}{{ tagIndex !== dto.tagIds.length - 1 ? ", " : "" }}</small
              >
            </p>
          </div>
          <div style="border-top: 1px solid #eff2f5"></div>
          <div
            class="mod-text-description fs-6 mt-2"
            v-if="
              dto.id !== null &&
              hasTranslationToCurrentLanguage(dto.description)
            "
          >
            {{ $getLocalizedValue(dto.description, $i18n.locale, true) }}
          </div>
          <div
            class="mod-text-description fs-6 mt-2"
            v-else-if="dto.id !== null"
          >
            {{ $t("oops_no_translation") }}
          </div>

          <div class="mt-2" v-if="dto.id !== null">
            <button
              class="btn btn-primary"
              v-if="hasAnyMissingTranslation(dto.description)"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_translation"
            >
              {{ $t("provide_english") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <fullscreen v-model="fullscreen"> </fullscreen>

  <div
    class="modal fade"
    id="kt_modal_translation"
    ref="translation_modal_ref"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-1000px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->
          <h2>{{ $t("provide_english") }}</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>

        <!--begin::Modal body-->
        <div class="modal-body" v-if="dto.id !== null">
          <EditFields
            :dto="translationDTO"
            :fields="translationFields"
            @updateDTO="onUpdateTranslationDTO"
          ></EditFields>
        </div>
        <!--end::Modal body-->

        <!--begin::Modal footer-->
        <div class="modal-footer flex-center">
          <!--begin::Button-->
          <button
            type="submit"
            class="btn btn-primary"
            @click="submitModTranslation"
          >
            <span class="indicator-label">
              {{ $t("mod_request_submit") }}
            </span>
          </button>
          <!--end::Button-->
        </div>
        <!--end::Modal footer-->
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import { toast } from "vue3-toastify";
import { saveAs } from "file-saver";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import EditFields from "@/components/EditFields";

export default defineComponent({
  name: "mod",
  components: { EditFields, Carousel, Slide, Navigation },
  computed: {
    tags() {
      return this.$store.getters.getTagsMap;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  data: function () {
    return {
      currentSlide: 0,
      dto: {
        id: null,
        description: null,
        size: 0,
        authors: [],
        tagIds: [],
      },

      translationDTO: {},
      translationFields: [
        {
          value: "description",
          name: "Description",
          type: "textarea-i18n",
        },
        {
          name: "Your email (optional)",
          value: "contact",
        },
      ],

      modInfo: [],

      slides: [],
      fullscreen: false,
    };
  },

  created: async function () {
    await ApiService.get("api", `mods/${this.$route.params.id}`)
      .then((response) => {
        this.dto = response.data;
        document.title = this.dto.title + " - Gro Repository";
        this.translationDTO = {
          description: this.dto.description,
          modId: this.dto.id,
        };
        for (let i = 0; i < this.dto.media.length; i++) {
          let media = this.dto.media[i];
          if (media.type === 2) {
            let videoId = this.youtube_parser(media.link);
            media.link = `https://www.youtube.com/embed/${videoId}`;
            media.previewLink = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
          }
          this.slides.push(media);
        }

        this.modInfo = [];
        this.modInfo.push({
          key: "gameTitle",
          translate: true,
          value: this.getGameName(this.dto.game),
        });
        this.modInfo.push({
          key: "materialTypeTitle",
          translate: true,
          value: this.getMaterialTypeName(this.dto.type),
        });
        this.modInfo.push({
          key: "gameModeTitle",
          translate: true,
          value: this.getGameTypeName(this.dto.subcategory),
        });

        let lang = localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en";

        const formatter = new Intl.DateTimeFormat(lang, {
          day: "numeric",
          month: "long",
          year: "numeric",
        });

        this.modInfo.push({
          key: "releaseDate",
          translate: false,
          value: this.dto.originalCreatedDate
            ? formatter.format(new Date(this.dto.originalCreatedDate))
            : "NULL",
        });

        if (this.dto.version) {
          this.modInfo.push({
            key: "version",
            translate: true,
            value: this.decorateVersion(this.dto.version),
          });
        }
      })
      .catch(() => {
        this.$router.push({ name: "404" });
      });
  },

  methods: {
    searchByTag(tagId) {
      this.$router.push({
        name: "mods",
        query: { tags: tagId },
      });
    },

    submitModTranslation: async function () {
      if (this.hasAllMissingTranslations(this.translationDTO.description)) {
        toast.error(this.$t('please_write_translation'));
        return;
      }

      await ApiService.post("api/mods/send-translation", this.translationDTO)
        .then(() => {
          toast.success(this.$t('translation_sent'));
        })
        .catch((error) => {
          toast.error(
            "Error " +
              error.response.data.code +
              "\n" +
              error.response.data.error
          );
        })
        .finally();
    },

    slideTo(val) {
      this.currentSlide = val;
    },

    onUpdateTranslationDTO: function(dto) {
      this.translationDTO = dto;
    },

    hasTranslationToCurrentLanguage(description) {
      if (!description) return false;

      let translated = this.$getLocalizedValue(
        description,
        this.$i18n.locale,
        true
      );
      return translated && translated != "Error" && translated != "";
    },

    hasAnyMissingTranslation(description) {
      if (!description) return true;

      let descEn = this.$getLocalizedValue(description, "en", true);
      let descFr = this.$getLocalizedValue(description, "fr", true);

      return (
        !descEn ||
        descEn == "" ||
        descEn == "Error" ||
        !descFr ||
        descFr == "" ||
        descFr == "Error"
      );
    },

    hasAllMissingTranslations(description) {
      if (!description) return true;

      let descEn = this.$getLocalizedValue(description, "en", true);
      let descFr = this.$getLocalizedValue(description, "fr", true);

      return (
        (!descEn || descEn == "" || descEn == "Error") &&
        (!descFr || descFr == "" || descFr == "Error")
      );
    },

    youtube_parser: function (url) {
      let regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      let match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },

    getTagName(tagId) {
      let tags = this.tags;
      return this.$getLocalizedValue(tags[tagId].name, this.$i18n.locale);
    },

    getGameTypeName: function (gameType) {
      switch (gameType) {
        case 0:
          return "singlePlayer";
        case 1:
          return "cooperative";
        case 2:
          return "singleAndCoop";
        case 3:
          return "deathmatch";
        case 4:
          return "allGameModes";
      }

      return "Unknown";
    },

    getGameName: function (gameIndex) {
      switch (gameIndex) {
        case 0:
          return "The First Encounter";
        case 1:
          return "The Second Encounter";
        case 2:
          return "TFE/TSE";
      }

      return "Unknown";
    },

    getMaterialTypeName: function (materialTypeIndex) {
      switch (materialTypeIndex) {
        case 0:
          return "mod_type_map";
        case 1:
          return "mod_type_mod";
        case 2:
          return "mod_type_skin";
        case 3:
          return "mod_type_resource";
        case 4:
          return "mod_type_dll";
        case 5:
          return "Модель оружия";
      }
      return "Unknown";
    },

    decorateVersion: function (version) {
      version = version + "";
      if (version.includes(".")) return version;

      return version + ".0";
    },

    bytesToSize: function (bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    edit: function () {
      this.$router.push({
        name: "admin-mod",
        params: { id: this.dto.id },
      });
    },

    userHasGrant: function (grant) {
      return (
        this.currentUser &&
        this.currentUser.roles &&
        this.currentUser.roles.indexOf(grant) !== -1
      );
    },

    download: function () {
      ApiService.get("api", "mods/download/" + this.dto.id)
        .then((response) => {
          saveAs(response.data);
        })
        .catch((error) => {
          toast.error("Unknown error");
        });
    },
  },
});
</script>
<style>
.mod-single-image {
  height: 40vh;
  display: block;
  margin: auto;
}

.mod-text-description {
  white-space: pre-line;
  text-align: justify;
}

.slide {
  width: 100%; /* Гарантирует, что слайд займет всю доступную ширину */
  max-width: 100%; /* Предотвращает выход за пределы контейнера */
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  height: 40vh;
  justify-content: center;
}

.iframe-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: block;
}

.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

@media (max-width: 768px) {
  .slide {
    height: auto; /* Или другое значение, подходящее для мобильных устройств */
  }
  .slide img {
    /* Возможные корректировки для мобильных устройств */
    width: 100%;
  }
}

.slide--thumbnail {
  cursor: pointer;
  width: 100%;
  padding: 0 4px;
  transition: opacity 0.3s;
}
.slide--thumbnail:hover {
  opacity: 0.75;
}

.slide--thumbnail img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  width: 100%;
}

.authors-container {
  display: flex;
  flex-direction: row; /* Stack items vertically */
}

.author-link {
  display: flex;
  align-items: center; /* Выравнивает содержимое по вертикали */
}

.author-avatar,
.author-icon {
  margin-right: 4px; /* Обеспечивает небольшой отступ справа от аватара или иконки */
}
</style>
